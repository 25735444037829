<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
       AdopteUnPM
      </div>

      <v-spacer></v-spacer>

      <v-btn
 @click="dialog = !dialog;"       
        text
      >
        <span class="mr-2">A propos</span>
      </v-btn>
     
  
    </v-app-bar>

    <v-main>
       <MglMap :zoom="9" :center="[5.22634,46.0505109]" :accessToken="accessToken" :mapStyle="mapStyle" >
        <template v-if="mode == 'etat'">

         <template v-for="(pm,i) of pms">
                <MglMarker v-if="pm.coords.lng && pm.coords.lat" :key="'pm_marker_'+i" :coordinates="[pm.coords.lng, pm.coords.lat]" :color="pm.etat == 'DEPLOYE' ? 'green': 'orange'" >
                  <MglPopup :closeButton="false">
        <v-card>
          <v-card-title>
            <h4 class="title">
              {{ pm.id }}
            </h4>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>Etat: {{ pm.etat }}</p>
            <p>Commune: {{ pm.commune.nom }}</p>
            <p>Précision de la localisation: {{pm.coords.accuracy == 100 ? 'précise': 'imprécise'}} </p>
            <p v-if="pm.dateOuvertureCommerciale.length > 0">
              Date d'ouverture commerciale: {{ pm.dateOuvertureCommerciale }}
            </p>
          </v-card-text>
        </v-card>
         
       
      </MglPopup >
                </MglMarker>

         </template>
        </template>
        <template v-else>
          
        </template>
       </MglMap>

    </v-main>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>
          <h3 class="title">A propos</h3>
          <v-spacer />
          <v-btn icon @click="dialog = false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="text-justify">
          <p class="mt-3">
            Ce projet a été créé afin de pouvoir suivre l'évolution du déploiement de la fibre du SIEA sur l'Ain (01).
            En effet, faute de communication, cette méthode permet de voir l'état d'avancement des points de mutualisations qui sont des étapes préalables à l'arrivée chez l'abonné.
            Si le point de mutualisation dont vous dépendez (pas toujours le plus proche!) est en état "déployé" il est probable que la fibre soit disponible prochainement (si elle n'est pas encore!)

          </p>
          <p>
            Ce projet n'a aucun lien ni affiliation avec le SIEA, responsable du deploiement de la fibre dans l'Ain.<br/>
            <a href="https://www.blum.dev/" target="_blank">Développé par Simon.</a>
            Un problème, une question, une remarque ? <a href="mailto:simon@blum.dev">Contactez-moi ! </a>
          </p>
          <p>
            Les points de mutualisation avec un marqueur "vert" sont en état "déployé". Orange signifie "en cours de déploiement".
            Certains PM ont une localisation imprécise, c'est donc le centre géographique de sa commune qui est utilisé. 
            </p>
            <p>
              Pour le moment ces données datent du 14 septembre et seront mis à jour quotidiennement très prochainement.
            </p>
            
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Mapbox from "mapbox-gl";
import pmSData from "../public/pms.json";
//import stats from "../public/enrich_stats_deploiement.json";

import { MglMap, MglMarker, MglPopup } from "vue-mapbox";
export default {
  name: 'App',

  components: {  MglMap,  MglMarker, MglPopup },

  data: () => ({
    pms: pmSData,
    communes: [],
    mode: 'etat',
    dialog: false,
    pointStats: [],
    mapStyle: 'mapbox://styles/mapbox/streets-v11',
    accessToken: 'pk.eyJ1Ijoic2ltb241MSIsImEiOiJja3lyZmlicHcwY2JiMzBxYThsdGtzMmNmIn0.LNTJ2T3cdtKU7tt6lkp4QQ'
  }),
    created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  },
  mounted() {

 

  }
};
</script>
<style>
.mapboxgl-popup-content {
  padding: 0 !important
}
</style>
